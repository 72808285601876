import "./VissionBox.scss";
import { HeadText } from "../../components/HeadText/HeadText";

export const VissionBox = () => {
  return (
    <div className="vision-section container">
      <div className="head-text-box">
        <HeadText text={"Vission"} />
      </div>
      <p className="vision-section__company-vission inter">
        Build a world where health and financial stability are inseparable
        components of every human journey. We envision a future where innovative
        technologies in healthcare and finance align with a deep understanding
        of human needs, providing simplicity and reliability in every aspect of
        life. Our aim is to make caring for health and finances natural and
        accessible to all, opening new horizons of prosperity and confidence in
        the future.
      </p>
    </div>
  );
};
