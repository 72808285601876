import "./CareersBox.scss";
import { HeadText } from "../../components/HeadText";
import { VacanciesBox } from "../../components/VacanciesBox";
import { useEffect, useState } from "react";
import leftArrow from "../../images/left-arrow.svg";
import rightArrow from "../../images/right-arrow.svg";
import { ref } from "firebase/database";
import { Spinner } from "../../components/Spinner";
import { database } from "../../firebase";
import { getFireDataBase } from "../../components/utils/getFireDataBase";

export const CareersBox = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [data, setData] = useState({});
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const dataRef = ref(database, "vacancies");
    setLoading(true);
    getFireDataBase(setData, setLoading, dataRef);
  }, []);

  const dataLength = Object.keys(data).length;
  const shouldShowButtons = dataLength > 3;

  const handleNext = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex + 1;
      if (newIndex + 3 > dataLength) {
        return 0;
      }
      return newIndex;
    });
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex - 1;
      if (newIndex < 0) {
        return Math.max(dataLength - 3, 0);
      }
      return newIndex;
    });
  };

  return (
    <div className="careers-box container">
      <div className="head-text-box">
        <HeadText text="Vacancies" />
      </div>
      <div className="careers-box__careers-space container">
        {isLoading ? (
          <div className="spinner container">
            <Spinner />
          </div>
        ) : !data || Object.keys(data).length === 0 ? (
          <div>
            <p>No vacancies available</p>
          </div>
        ) : (
          Object.keys(data)
            .slice(currentIndex, currentIndex + 3)
            .map((key) => {
              const job = data[key];
              return (
                <VacanciesBox
                  key={key}
                  vacanciesName={key}
                  jobTitle={job.jobTitle}
                  workDay={job.workDay}
                  workPlace={job.workPlace}
                  jobPosition={job.jobPosition}
                  workRegion={job.workRegion}
                  status={job.status}
                  description={job.description}
                />
              );
            })
        )}
      </div>
      {shouldShowButtons && (
        <div className="careers-box__steps-btns flex">
          <button className="careers-box__btn1" onClick={handlePrev}>
            <img style={{ width: "10px" }} src={leftArrow} alt="arrow" />
          </button>
          <button className="careers-box__btn2" onClick={handleNext}>
            <img style={{ width: "10px" }} src={rightArrow} alt="arrow" />
          </button>
        </div>
      )}
    </div>
  );
};
