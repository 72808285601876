import "./ProjectsBox.scss";
import { HeadText } from "../../components/HeadText/HeadText";
import { ProjectCard } from "../../components/ProjectCard";
import { useEffect, useState } from "react";
import { ref } from "firebase/database";
import leftArrow from "../../images/left-arrow.svg";
import rightArrow from "../../images/right-arrow.svg";
import { Spinner } from "../../components/Spinner/Spinner";
import { database } from "../../firebase";
import { getFireDataBase } from "../../components/utils/getFireDataBase";

export const ProjectsBox = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [data, setData] = useState({});
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const dataRef = ref(database, "projects");
    setLoading(true);
    getFireDataBase(setData, setLoading, dataRef);
  }, []);

  const dataLength = Object.keys(data).length;
  const shouldShowButtons = dataLength > 3;

  const handleNext = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex + 1;
      if (newIndex + 3 > dataLength) {
        return 0;
      }
      return newIndex;
    });
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex - 1;
      if (newIndex < 0) {
        return Math.max(dataLength - 3, 0);
      }
      return newIndex;
    });
  };
  return (
    <div className="our-project container">
      <div className="head-text-box">
        <HeadText text={"Our projects"} />
      </div>
      <div className="our-project__all-cards">
        {isLoading ? (
          <div className="spinner container">
            <Spinner />
          </div>
        ) : (
          Object.keys(data)
            .slice(currentIndex, currentIndex + 3)
            .map((key) => {
              const project = data[key];
              return (
                <ProjectCard
                  key={project.name}
                  projName={project.name}
                  projDescr={project.description}
                  projPeopleNum={project.peopleNum}
                  projTimeline={project.timeline}
                  projLink={project.link}
                />
              );
            })
        )}
      </div>
      <div className="our-project__botton-position">
        {shouldShowButtons && (
          <div className="our-project__steps-btns flex">
            <button className="our-project__btn1" onClick={handlePrev}>
              <img style={{ width: "10px" }} src={leftArrow} alt="arrow" />
            </button>
            <button className="our-project__btn2" onClick={handleNext}>
              <img style={{ width: "10px" }} src={rightArrow} alt="arrow" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
