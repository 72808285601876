import "./ArrowVacanciesPopup.scss";
import PropTypes from "prop-types";
import vector from "../../images/Vector.svg";
import Popup from "reactjs-popup";
import { useState } from "react";
import cvImg from "../../images/cvImg.svg";
import cancelBtn from "../../images/cancelBtn.svg";
import { ref, set, push } from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { database } from "../../firebase";
import { checkFilledField } from "../utils/checkFilledField";

const propTypes = {
  vacanciesName: PropTypes.string.isRequired,
};

export const ArrowVacanciesPopup = ({ vacanciesName }) => {
  const [fileName, setFileName] = useState("Choose file");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [cv, setCv] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const storage = getStorage();

  const handleSendPortfolio = (e, vacanciesId) => {
    checkFilledField(e, name, email);
    if (!cv) {
      toast("No file selected");
      return;
    }

    const userId = push(ref(database, "users")).key;
    const storagePath = `cv/${userId}/${cv.name}`;
    const storageFileRef = storageRef(storage, storagePath);

    uploadBytes(storageFileRef, cv)
      .then((snapshot) => {
        return getDownloadURL(snapshot.ref);
      })
      .then((downloadURL) => {
        return set(ref(database, `portfolio/${vacanciesId}/${userId}`), {
          userId: userId,
          name: name,
          email: email,
          message: message,
          cvUrl: downloadURL,
        });
      })
      .then(() => {
        toast("Your portfolio saved!");
        setIsOpen(false);
      })
      .catch((error) => {
        toast("Error saving portfolio");
      });
  };

  const handleFileChange = (e) => {
    setCv(e.target.files[0]);
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
    } else {
      setFileName("Choose file");
    }
  };

  const handleRemoveFile = () => {
    setFileName("Choose file");
    document.getElementById("file").value = "";
  };

  return (
    <Popup
      trigger={
        <button className="vacancies-popup-btn">
          <img src={vector} alt="arrow" />
        </button>
      }
      modal
      nested
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      contentStyle={{ padding: "0", border: "none" }}
    >
      {(close) => (
        <div
          className={`vacancies-popup ${isOpen ? "vacancies-popup-open" : ""}`}
        >
          <div className="vacancies-popup__form-content">
            <div className="vacancies-popup__top-panel-pop-up inter">
              Your personal data
              <button className="vacancies-popup__close-btn" onClick={close}>
                <img src={cancelBtn} alt="close" />
              </button>
            </div>

            <div className="vacancies-popup__text-field flex">
              <div className="vacancies-popup__personal-info">
                <form>
                  <label htmlFor="name">
                    <p className="vacancies-popup__name-label inter">
                      First and Last name
                    </p>
                  </label>
                  <input
                    className="vacancies-popup__first-last-name inter"
                    type="name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <label htmlFor="email" className="inter">
                    <p className="vacancies-popup__email-label inter">Email</p>
                  </label>
                  <input
                    className="vacancies-popup__email-field inter"
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </form>
              </div>
              <div className="vacancies-popup__message-place">
                <label className="inter" htmlFor="message">
                  <p className="vacancies-popup__message-label inter">
                    Message
                  </p>
                </label>
                <textarea
                  className="vacancies-popup__message-text-form"
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                />
                <label htmlFor="file" className="inter">
                  <p className="vacancies-popup__cv-label inter">CV</p>
                </label>
                <div className="vacancies-popup__file-place">
                  <button
                    className="vacancies-popup__cv-btn"
                    onClick={() => {
                      document.getElementById("file").click();
                      return false;
                    }}
                  >
                    <img
                      src={cvImg}
                      alt="cv"
                      className="vacancies-popup__cv-img"
                    />
                    {fileName}
                  </button>
                  {fileName !== "Choose file" && (
                    <p
                      className="vacancies-popup__remove-file"
                      onClick={handleRemoveFile}
                    >
                      <img src={cancelBtn} alt="cancel" />
                    </p>
                  )}
                  <input
                    className="vacancies-popup__cv-field inter"
                    type="file"
                    name="file"
                    id="file"
                    onChange={handleFileChange}
                  />
                </div>
                <button
                  className="vacancies-popup__send-form-button inter"
                  type="submit"
                  onClick={(e) => handleSendPortfolio(e, vacanciesName)}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
};

ArrowVacanciesPopup.propTypes = propTypes;
