import PropTypes from "prop-types";
import "./FlexBox.scss";

const propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
};
export const FlexBox = ({ text, icon }) => {
  return (
    <div className="flex-box">
      <div className="flex-box__icon-div">
        <img src={icon} alt="icon" />
      </div>
      <p className="flex-box__more-info-text inter">{text}</p>
    </div>
  );
};

FlexBox.propTypes = propTypes;
