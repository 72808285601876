import "./HomePage.scss";
import { MainHeaderBox } from "../../sections/MainHeaderBox";
import { AboutUsBox } from "../../sections/AboutUsBox";
import { MissionBox } from "../../sections/MissionBox";
import { VissionBox } from "../../sections/VissionBox";
import { JobTitle } from "../../sections/JobTitle";
import { PartnershipBox } from "../../sections/PartnershipBox";
import { ProjectsBox } from "../../sections/ProjectsBox";
import { StatisticBox } from "../../sections/StatisticBox";
import { BusinessModel } from "../../sections/BusinessModel";
import { CareersBox } from "../../sections/CareersBox";
import { FeedbackBox } from "../../sections/FeedbackBox";
import { BottomPanel } from "../../sections/BottomPanel";
import { HeaderMenu } from "../../components/HeaderMenu/HeaderMenu";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import grad1Bg from "../../images/grad1bg.png";
import grad2Bg from "../../images/grad2bg.png";
import "react-toastify/dist/ReactToastify.css";
import { MobProjectBox } from "../../sections/MobProjectBox";
import { MobCareersBox } from "../../sections/MobCareersBox";

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: { y: 0, opacity: 1 },
};

const AnimatedSection = ({ children }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      variants={item}
      transition={{ duration: 0.6 }}
    >
      {children}
    </motion.div>
  );
};

export const HomePage = () => {
  return (
    <>
      <HeaderMenu />
      <div className="brainly-app">
        <div className="container">
          <>
            <div id="mainheaderbox">
              <AnimatedSection>
                <MainHeaderBox />
              </AnimatedSection>
            </div>
            <div id="about-us">
              <AnimatedSection>
                <AboutUsBox />
              </AnimatedSection>
            </div>
            <div id="missionbox">
              <AnimatedSection>
                <MissionBox />
              </AnimatedSection>
            </div>
            <div>
              <AnimatedSection>
                <VissionBox />
              </AnimatedSection>
            </div>
            <div id="services">
              <AnimatedSection>
                <JobTitle />
              </AnimatedSection>
            </div>
            <div>
              <AnimatedSection>
                <PartnershipBox />
              </AnimatedSection>
            </div>
            <div>
              <AnimatedSection>
                <div className="comp-project-box">
                  <ProjectsBox />
                </div>
                <div className="mob-project-box">
                  <MobProjectBox />
                </div>
              </AnimatedSection>
            </div>
            <div>
              <AnimatedSection>
                <StatisticBox />
              </AnimatedSection>
            </div>
            <div id="business-model">
              <AnimatedSection>
                <BusinessModel />
              </AnimatedSection>
            </div>
            <div id="careers">
              <AnimatedSection>
                <div className="comp-careers-box">
                  <CareersBox />
                </div>
                <div className="mob-careers-box">
                  <MobCareersBox />
                </div>
              </AnimatedSection>
            </div>
            <div id="feedback">
              <AnimatedSection>
                <FeedbackBox />
              </AnimatedSection>
            </div>
            <div>
              <AnimatedSection>
                <BottomPanel />
              </AnimatedSection>
            </div>
          </>
        </div>
        <div className="brainly-app__gradient-bg">
          <img className="brainly-app__gradient1" src={grad1Bg} alt="grad" />
        </div>
        <div className="brainly-app__gradient-bg">
          <img className="brainly-app__gradient2" src={grad2Bg} alt="grad" />
        </div>
      </div>
    </>
  );
};
