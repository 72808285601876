import React, { useState, useEffect, useRef } from "react";
import { ModelBoxAnim } from "../../components/ModelBoxAnim/ModelBoxAnim";
import "./BusinessModel.scss";
import { motion, useInView } from "framer-motion";

export const BusinessModel = () => {
  const modelNameArr1 = [
    ["Discovery", "Features Priorities", "UX/UI Design", "Development", "Testing", "Support"],
    [
      "In the Research phase, we gather and analyze market trends, user needs, and technical requirements to ensure your product aligns with customer expectations and business goals. This critical step lays a solid foundation for successful development and exceptional results.",
      "During the Features Prioritization phase, we identify and rank the most critical features to ensure your product delivers maximum value to users. By focusing on high-impact functionalities, we streamline development and optimize the user experience.",
      "We craft intuitive and visually appealing interfaces that enhance user experience. Our design experts transform your vision into functional, user-friendly layouts that make your product stand out.",
      "Our skilled engineers bring your product to life with clean, efficient code. We ensure robust functionality and seamless performance, turning designs into fully operational, high-quality software.",
      "We rigorously evaluate your product to ensure it meets the highest standards of quality and performance. Our comprehensive testing process identifies and resolves any issues, guaranteeing a flawless user experience.",
      "We provide ongoing maintenance and updates to keep your product running smoothly and efficiently. Our dedicated team is always available to address any issues, implement enhancements, and ensure your software remains up-to-date with the latest technologies.",
    ],
  ];

  const [hasAnimated, setHasAnimated] = useState(false);

  const ref = useRef(null);
  const isInView = useInView(ref, { threshold: 0.2 });

  useEffect(() => {
    if (isInView && !hasAnimated) {
      setHasAnimated(true);
    }
  }, [isInView, hasAnimated]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const topAnimation = {
    hidden: { opacity: 0, y: 0 },
    visible: { opacity: 1, y: -50 },
  };

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const shouldRenderLineBox = (index) => {
    if (screenWidth > 1180) {
      return index !== 2 && index !== 5;
    } else if (screenWidth <= 1180 && screenWidth > 740) {
      return index !== 1 && index !== 3 && index !== 5;
    } else {
      return false;
    }
  };

  return (
    <div className="business-model container flex" ref={ref}>
      <div className="business-model__model-content">
        <motion.div
          className="business-model__top-model-panel flex"
          initial="hidden"
          animate={hasAnimated ? "visible" : "hidden"}
          variants={topAnimation}
          transition={{ delay: 1 * 0.5, duration: 0.5 }}
        >
          {modelNameArr1[0].map((names, index) => (
            <React.Fragment key={index}>
              <motion.div
                variants={topAnimation}
                transition={{ delay: index * 0.5, duration: 0.5 }}
              >
                <ModelBoxAnim
                  model={names}
                  aboutModel={modelNameArr1[1][index]}
                />
              </motion.div>
              <motion.div
                variants={topAnimation}
                transition={{ delay: index * 0.5, duration: 0.5 }}
              >
                {shouldRenderLineBox(index) && (
                  <div className="business-model__line-box"></div>
                )}
              </motion.div>
            </React.Fragment>
          ))}
        </motion.div>
      </div>
    </div>
  );
};
