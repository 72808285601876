import "./NumAnimation.scss";
import PropTypes from "prop-types";
import { useEffect, useState, useRef } from "react";
import TextTransition, { presets } from "react-text-transition";

const propTypes = {
  textInfo: PropTypes.string.isRequired,
  plus: PropTypes.string.isRequired,
};

export const NumAnimation = ({ number, textInfo, plus }) => {
  const animateNumArr = [];
  for (let i = 0; i < number; i++) {
    animateNumArr.push(i + 1);
  }

  const [textIndex, setTextIndex] = useState(0);
  const [inView, setInView] = useState(false);
  const numAnimationRef = useRef(null);

  useEffect(() => {
    const currentRef = numAnimationRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInView(true);
        }
      },
      {
        threshold: 0.5,
      }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  useEffect(() => {
    if (inView && textIndex < number) {
      const interval = setInterval(() => {
        setTextIndex((index) => index + 1);
      }, 50);
      return () => clearInterval(interval);
    }
  }, [inView, textIndex, number]);

  return (
    <div className="box-numbers-info" ref={numAnimationRef}>
      <div className="box-numbers-info__animation-for-num">
        <TextTransition springConfig={presets.wobbly}>
          <p className="box-numbers-info__animate-num inter">
            {textIndex < number ? animateNumArr[textIndex] : number} {plus}
          </p>
        </TextTransition>
      </div>
      <p className="box-numbers-info__about-num inter">{textInfo}</p>
    </div>
  );
};

NumAnimation.propTypes = propTypes;
