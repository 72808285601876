import { onValue } from "firebase/database";

export const getFireDataBase = (setData, setLoading, dataRef) => {
  onValue(
    dataRef,
    (snapshot) => {
      const value = snapshot.val();
      setData(value || {});
      setLoading(false);
    },
    (error) => {
      console.error("Error fetching data: ", error);
      setLoading(false);
    }
  );
};
