import { useEffect } from "react";
import { useLottie } from "lottie-react";
import "./RobotAnimation.scss";
import animationData from "../../images/lottievideo.json";

export const RobotAnimation = () => {
  const options = {
    animationData,
    loop: false,
  };

  const { View, animationItem } = useLottie(options);

  useEffect(() => {
    if (animationItem) {
      animationItem.play();
      animationItem.addEventListener("complete", () => {
        animationItem.playSegments([173, 400], true);
      });
    }
  }, [animationItem]);

  return View;
};
