import "./MainHeaderBox.scss";
import { ContactBtn } from "../../components/ContactBtn";
import { RobotAnimation } from "../../components/RobotAnimation";

export const MainHeaderBox = () => {
  return (
    <div className="banner-box container flex">
      <div className="banner-box__main-info-box">
        <p className="banner-box__about-company">
          Our company provides cutting-edge solutions for designing convenient
          and appealing user interfaces.
        </p>
        <p className="banner-box__about-team bruno-ace">
          Our team of experts transforms your ideas into modern, functional
          designs, ensuring an exceptional user experience for your product.
        </p>
        <ContactBtn btnText={"Contact us"} />
      </div>
      <div className="banner-box__robot-animation">
        <RobotAnimation />
      </div>
    </div>
  );
};
