import "./ProjectCard.scss";
import arrow from "../../images/arrow.svg";
import clock from "../../images/carbon_time.svg";
import user from "../../images/solar_user-linear.svg";
import rectangle from "../../images/logoImg.svg";
import PropTypes from "prop-types";

const propTypes = {
  projName: PropTypes.string.isRequired,
  projDescr: PropTypes.string.isRequired,
  projPeopleNum: PropTypes.number.isRequired,
  projTimeline: PropTypes.string.isRequired,
  projLink: PropTypes.string.isRequired,
};

export const ProjectCard = ({
  projName,
  projDescr,
  projPeopleNum,
  projTimeline,
  projLink,
}) => {
  return (
    <div className="project-card flex">
      <div className="project-card__about-project">
        <div className="project-card__name-field">
          <p className="project-card__project-name">
            {projName} <img src={rectangle} alt="Rectangle" />
          </p>
        </div>
        <div className="project-card__project-description">
          <p className="project-card__project-info inter">{projDescr}</p>
        </div>
        <div className="project-card__additional-info flex">
          <div className="project-card__project-duration">
            <img
              className="project-card__time-carbon"
              src={clock}
              alt="clock"
            />
            <p className="project-card__project-time inter">{projTimeline}</p>
          </div>
          <div className="project-card__supporters">
            <img className="project-card__solar-user" src={user} alt="user" />
            <p className="project-card__people-num inter">
              {projPeopleNum} people
            </p>
          </div>
        </div>
        <a className="project-card__link-btn" href={projLink}>
          Link
          <img src={arrow} alt="arrow" />
        </a>
      </div>
    </div>
  );
};

ProjectCard.propTypes = propTypes;
