import styled from "styled-components";

const StyledBurger = styled.button`
  top: 30px;
  left: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1001;
  @media (min-width: 850px) {
    display: none;
  }
  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: ${({ open }) => (open ? "#05D4F5" : "#FFFFFF")};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
  @media (max-width: 440px) {
    width: 2rem;
    height: 1.5rem;
    div {
      width: 20px;
    }
  }
`;

export const BurgerMenu = ({ open, setOpen }) => {
  return (
    <div className="burger-mob-menu-ico">
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>
    </div>
  );
};
