import "./AboutUsBox.scss";
import { HeadText } from "../../components/HeadText/HeadText";

export const AboutUsBox = () => {
  return (
    <div className="info-about-us container">
      <div className="head-text-box">
        <HeadText text={"About us"} />
      </div>
      <p className="info-about-us__text-info inter">
        Brainly Solution is a technology company specializing in full-cycle product development, 
        offering services in Data Science, AI, DevOps Service, Mobile and Web Development, UX/UI Design and Software Testing.
        We provide tailored consulting and scalable engineering support, helping businesses enhance 
        their technological capabilities. With a focus on quality, transparency, and flexibility, 
        we deliver cost-effective solutions designed to meet the unique needs of each client. 
        At Brainly Solution, we empower companies to thrive in a fast-evolving digital world.
      </p>
    </div>
  );
};
