import { useEffect, useState } from "react";
import { HeadText } from "../../components/HeadText";
import { PartnersCard } from "../../components/PartnersCard/PartnersCard";
import "./FeedbackBox.scss";
import { ref, onValue } from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  getDownloadURL,
} from "firebase/storage";
import unknownPhoto from "../../images/unknown.jpg";
import { Spinner } from "../../components/Spinner/Spinner";
import leftArrow from "../../images/left-arrow.svg";
import rightArrow from "../../images/right-arrow.svg";
import { database } from "../../firebase";

export const FeedbackBox = () => {
  const [data, setData] = useState({});
  const [images, setImages] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setLoading] = useState(true);

  const storage = getStorage();

  useEffect(() => {
    const dataRef = ref(database, "feedback");
    setLoading(true);
    onValue(
      dataRef,
      (snapshot) => {
        const value = snapshot.val();
        setData(value || {});

        const imagePromises = Object.keys(value).map((key) => {
          const feedback = value[key];
          if (feedback.photoPath) {
            const photoRef = storageRef(storage, feedback.photoPath);
            return getDownloadURL(photoRef)
              .then((url) => ({ key, url }))
              .catch(() => ({ key, url: unknownPhoto }));
          }
          return Promise.resolve({ key, url: unknownPhoto });
        });

        Promise.all(imagePromises.filter(Boolean)).then((imageData) => {
          const imagesMap = {};
          imageData.forEach(({ key, url }) => {
            imagesMap[key] = url;
          });
          setImages(imagesMap);
        });
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching data(feedback): ", error);
        setLoading(false);
      }
    );
  }, [storage]);

  const dataLength = Object.keys(data).length;
  const shouldShowButtons = dataLength > 3;

  const handleNext = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex + 1;
      if (newIndex + 3 > dataLength) {
        return 0;
      }
      return newIndex;
    });
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex - 1;
      if (newIndex < 0) {
        return Math.max(dataLength - 3, 0);
      }
      return newIndex;
    });
  };

  return (
    <div className="feedback-box container">
      <div className="head-text-box">
        <HeadText text="Feedback from our partners" />
      </div>
      <div className="feedback-box__partners-feedbacks">
        {isLoading ? (
          <div className="spinner container">
            <Spinner />
          </div>
        ) : !data || Object.keys(data).length === 0 ? (
          <div>
            <p>No feedback yet</p>
          </div>
        ) : (
          Object.keys(data)
            .slice(currentIndex, currentIndex + 3)
            .map((key) => {
              const feedback = data[key];
              const imageUrl = images[key];
              return (
                <PartnersCard
                  key={key}
                  photo={imageUrl}
                  name={feedback.partnerName}
                  comment={feedback.comment}
                />
              );
            })
        )}
      </div>
      <div className="feedback-box__botton-position">
        {shouldShowButtons && (
          <div className="feedback-box__steps-btns flex">
            <button className="feedback-box__btn1" onClick={handlePrev}>
              <img style={{ width: "10px" }} src={leftArrow} alt="arrow" />
            </button>
            <button className="feedback-box__btn2" onClick={handleNext}>
              <img style={{ width: "10px" }} src={rightArrow} alt="arrow" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
