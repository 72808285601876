import { useState } from "react";
import "./PartnersCard.scss";
import PropTypes from "prop-types";

const propTypes = {
  name: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
};

export const PartnersCard = ({ name, comment, photo }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="partner-card">
      <div className="partner-card__partner-info flex">
        <img
          className="partner-card__partner-photo"
          src={photo}
          alt="Our partner"
        />
        <div className="partner-card__partner-description">
          <p className="partner-card__partner-name bruno-ace">{name}</p>
          <p
            className={`partner-card__partner-comment inter ${
              isExpanded ? "expanded" : ""
            }`}
            onClick={toggleExpand}
          >
            {comment}
          </p>
        </div>
      </div>
    </div>
  );
};

PartnersCard.propTypes = propTypes;
