import { useState } from "react";
import "./ContactBtn.scss";
import PropTypes from "prop-types";
import Popup from "reactjs-popup";
import { ref, set, push } from "firebase/database";
import cancelBtn from "../../images/cancelBtn.svg";
import { toast } from "react-toastify";
import { database } from "../../firebase";
import { checkFilledField } from "../utils/checkFilledField";

const propTypes = {
  btnText: PropTypes.string.isRequired,
};

export const ContactBtn = ({ btnText }) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name || !email) {
      toast("Not all fields are filled in");
      return;
    }
    checkFilledField(e, name, email);
    const userId = push(ref(database, "users")).key;
    set(ref(database, "contact/" + userId), {
      userId: userId,
      name: name,
      email: email,
      message: message,
    })
      .then(() => {
        toast("Your contact info has been saved");
        setIsOpen(false);
      })
      .catch((error) => {
        toast("Something is wrong, check your entered data");
      });
  };

  return (
    <Popup
      trigger={
        <button className="contact-button button-anim">{btnText}</button>
      }
      modal
      nested
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      contentStyle={{ padding: "0", border: "none" }}
    >
      {(close) => (
        <div className={`contact-form ${isOpen ? "contact-form-open" : ""}`}>
          <div className="contact-form__form-content">
            <div className="contact-form__top-panel-pop-up inter">
              Your personal data
              <button className="contact-form__close-btn" onClick={close}>
                <img src={cancelBtn} alt="close" />
              </button>
            </div>

            <div className="contact-form__text-field flex">
              <div className="contact-form__personal-info">
                <form>
                  <label htmlFor="name">
                    <p className="contact-form__name-label inter">
                      First and Last name
                    </p>
                  </label>
                  <input
                    className="contact-form__first-last-name inter"
                    type="name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <label htmlFor="email" className="inter">
                    <p className="contact-form__email-label inter">Email</p>
                  </label>
                  <input
                    className="contact-form__email-field inter"
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </form>
              </div>
              <div className="contact-form__message-place">
                <label className="inter" htmlFor="message">
                  <p className="contact-form__message-label inter">Message</p>
                </label>
                <textarea
                  className="contact-form__message-text-form"
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                />

                <button
                  className="contact-form__send-form-button inter"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
};

ContactBtn.propTypes = propTypes;
