import "./PrivacyPage.scss";
import { HeadText } from "../../components/HeadText";
import { NavLink } from "react-router-dom";

export const PrivacyPage = () => {
  return (
    <div className="privacy-box container">
      <NavLink to="/" className="link-to-home">
        &#60;Home
      </NavLink>
      <div className="privacy-box__all-privacy-info">
        <h1 className="privacy-box__header-privacy-policy">
          <HeadText text="Our Privacy Policy" />
        </h1>
        <p className="privacy-box__cookies-text-policy inter">
          <p className="cookies-box__head-policy-text inter">Privacy Policy</p>
          Brainly Solution (or "we" or "us") respects and supports your right to
          privacy. Brainly Solution complies with applicable local laws and
          regulations and applicable data protection standards regarding the
          collection, use, disclosure, storage and transfer of personal
          information from individuals.
          <br />
          <br />
          This privacy policy ("Privacy Policy") describes in detail the methods
          we have applied to protect your privacy so that you can understand how
          we manage your "personal information" (based on the Constitution of
          Ukraine and the Law of Ukraine "On Protection of Personal Data" dated
          01.06 2010 No. 2297-VI (hereinafter - the Law), as well as the General
          Data Protection Regulation (EU) 2016/679 of 04/27/2016 (EU General
          Data Protection Regulation, hereinafter - GDPR) and other applicable
          European data protection legislation) collectively referred to as
          "personal information" in this Privacy Policy) that you entrust to us.
          <br />
          <br />
          This privacy policy is provided in a multi-level format, so you can
          link to specific sections below. Alternatively, you can download a pdf
          version of the policy here:
          <br />
          <br />
          Please also use the Glossary to understand the meaning of some of the
          terms used in this privacy policy.
          <br />
          <br />
          <ol className="privacy-box__marker-list-cookies">
            <li className="inter">Who we are and important information</li>
            <li className="inter">
              What personal information do we collect from people who visit our
              website?
            </li>
            <li className="inter">How is your personal data collected?</li>
            <li className="inter">How we use your personal data</li>
            <li className="inter">Disclosure of your personal data</li>
            <li className="inter">Data security</li>
            <li className="inter">Your legal rights</li>
            <li className="inter">How to contact us</li>
            <li className="inter">Glossary</li>
          </ol>
          <br />
          This Privacy Policy also describes how you can contact us about your
          personal information and, at your request, opt out of this Privacy
          Policy, limit the use of, or change any personal information we may
          have about you. <br />
          <br />
          By providing us with personal information about yourself through our
          website or otherwise, you consent to the collection, use, disclosure,
          storage and transfer of that personal information as described in this
          Privacy Policy. If at any time you wish to withdraw your consent to
          the collection, use, disclosure, storage and transfer of your personal
          information in accordance with our Privacy Policy, you may opt-out by
          contacting us (see contact us below ) or immediately stop providing
          any further personal information about you to Brainly Solution through
          its website or otherwise.
          <p className="privacy-box__head-policy-text inter">
            1. Who we are and important information
          </p>
          1. Who is the owner of personal data?
          <br />
          <br />
          The owner of personal data is Brainly Solution (hereinafter -
          "Ukrainian City", "Project" or "we") is a company registered in
          Ukraine, EDRPOU Code 44703050, address: 18003, Cherkasy Region,
          Cherkasy City, Lupinosa Anatoliya St. , building 44 <br />
          <br />
          If you have any questions regarding the processing of your personal
          data, you can send us a letter to the specified address or contact us
          via any communication channel indicated on the Project website -
          brainlysooolution@gmail.com <br />
          <br />
          Also, regarding your personal data processed by the Bank, you can
          contact the address brainlysooolution@gmail.com.
          <br />
          <br />
          2. Purpose of this privacy policy
          <br />
          <br />
          This privacy policy is intended to provide you with information about
          how Brainly Solution collects and processes your personal data when
          you use this website.
          <br />
          <br />
          It is important that you read this privacy policy together with any
          other privacy policy we may provide in specific cases where we collect
          or process personal data about you so that you are fully aware of how
          and why we use your data.
          <br />
          <br />
          3. Third party links
          <br />
          <br />
          This website may contain links to third party websites. Clicking on
          these links may allow third parties to collect or share data about
          you. We do not control these third-party websites and are not
          responsible for their privacy statements. When you leave our website,
          we encourage you to read the privacy policy of each website you visit.
          <p className="privacy-box__head-policy-text inter">
            2. What personal information do we collect from people who visit our
            website?
          </p>
          Generally, the personal information we collect is limited to:
          <br />
          <br />
          <i className="privacy-box__italic inter">Contact details</i>, which
          include your email address(es).
          <br />
          <i className="privacy-box__italic inter">Identification data,</i>{" "}
          which include your name, surname, and the name of your organization.
          <br />
          <i className="privacy-box__italic inter">Usage data</i> contain
          information about how you use our website.
          <br />
          <i className="privacy-box__italic inter">
            Marketing and communications data
          </i>
          , which include yours preferences in receiving marketing from us and
          yours preferences in communication
          <br />
          <i className="privacy-box__italic inter">Technical data</i>, which
          which includes your Internet Protocol (IP) address, login information,
          browser type and version, time zone and location settings, browser
          plug-in types and versions, operating system, platform and other
          technologies on the devices you use to access of this website.
          <br />
          <br />
          We also collect, use and provide aggregate data, such as statistical
          or demographic data, for any purpose. Aggregated data may be derived
          from your personal data, but is not considered personal data under the
          law, as this data does not directly or indirectly reveal your
          identity.
          <br />
          <br />
          For example, we may aggregate usage data to calculate the percentage
          of users who access a particular website feature. However, if we
          combine or link Aggregate Data with your personal data so that it can
          directly or indirectly identify you, we treat the combined data as
          personal data that will be used in accordance with this privacy
          policy.
          <br />
          <br />
          We do not collect personal information about you other than
          information that you voluntarily provide provide us through this site
          to fulfill the purpose of your request.
          <br />
          <br />
          We may also collect other personal information that is contained in
          the content that you send to us.
          <br />
          <br />
          2. How long do we process personal information?
          <br />
          <br />
          We only process personal information for as long as is necessary for
          the purpose(s) for which it was originally collected, after which it
          will be deleted or archived, except where necessary for continued
          processing to comply with legal obligations , to which we are subject,
          or for another lawful and lawful purpose. We will retain your personal
          information only when we consider it necessary for one of the purposes
          for which you disclosed it.
          <br />
          <br />
          If it is no longer necessary to store your personal information, we
          will take reasonable steps to securely destroy it or de-identification
          Your personal information, if necessary.
          <br />
          <br />
          In some cases, we anonymize your personal data (so that it can no
          longer be linked to you) for research or statistical purposes, in
          which case we may use this information indefinitely without further
          notice.
          <br />
          <br />
          3. If you do not provide personal data
          <br />
          <br />
          If we are required to collect personal data by law or under the terms
          of a contract we enter into with you, and you do not provide this data
          upon request, we may not be able to perform the contract we have or
          are trying to enter into with you (for example, to provide you goods
          or services). In this event, we may have to cancel the product or
          service you provide to us, but we will notify you if this is the case
          at the time.
          <p className="privacy-box__head-policy-text inter">
            3. How is your personal data collected?
          </p>
          We use various methods to collect data from and about you, including
          through:
          <br />
          <br />
          <i className="privacy-box__italic inter">Direct interactions</i>. You
          can provide us with your identity and contact information by filling
          out forms or contacting us by email. This includes personal data you
          provide when:
          <br />
          <br />
          - you send us a request for cooperation; or
          <br />- give us feedback or get in touch with us <br />
          <br />
          <i className="privacy-box__italic inter">
            Automated technologies or interactions
          </i>
          .When you interact with our website, we automatically we will collect
          technical data about your equipment, actions and viewing patterns. We
          collect this personal data using cookies, server logs, and other
          similar technologies. We may also obtain Technical Data about you if
          you visit other websites that use our cookies. Please read our cookie
          policy (
          <a href="https://www.brainlysolution.com/cookies" className="inter">
            https://www.brainlysolution.com/cookies
          </a>
          ) for more information.
          <p className="privacy-box__head-policy-text inter">
            4. How we use your personal data
          </p>
          We will only use your personal data when permitted by law. We will
          most often use your personal data in the following circumstances:{" "}
          <br />
          <br />
          If it is necessary for our legitimate interests (or those of a third
          party) and your interests and fundamental rights do not override those
          interests.
          <br />
          <br />
          Where we need to comply with a legal obligation.
          <br />
          <br />
          <i className="privacy-box__italic inter">
            Purposes for which we will use your personal data
          </i>
          <br />
          <br />
          Below, we have provided in table format a description of the ways in
          which we plan to use your personal data and on what legal basis we
          rely for this.
          <br />
          <br />
          Please note that we may process your personal data on several lawful
          grounds depending on the specific purpose for which we use your data.
          Please contact us if you require information about the specific legal
          basis on which we rely to process your personal data if more than one
          basis is listed in the table below.
          <br />
          <br />
          <table className="privacy-box__info-table">
            <tr className="privacy-box__info-rows">
              <th className="privacy-box__info-rows inter">Purpose/Activity</th>
              <th className="privacy-box__info-rows inter">Type data</th>
              <th className="privacy-box__info-rows inter">
                Lawful basis for processing, including legitimate interest
              </th>
            </tr>
            <tr className="privacy-box__info-rows">
              <td className="privacy-box__info-rows inter">
                To administer and protect our the project and this website
                (including troubleshooting, data analysis, testing, system
                maintenance, support, reporting and data posting)
              </td>
              <td className="privacy-box__info-rows inter">
                (a) Identity
                <br />
                (b) Contact
              </td>
              <td className="privacy-box__info-rows inter">
                (a) Necessary for our legitimate interests to conduct our the
                project, provision of administrative and IT services, network
                security, to prevent fraud and in the context of reorganization
                the project or restructuring of the group
                <br />
                (b) Necessary to comply with a legal obligation
              </td>
            </tr>
            <tr className="privacy-box__info-rows">
              <td className="privacy-box__info-rows inter">
                To use data analytics to improve our website, products/services,
                marketing, customer relations and experience
              </td>
              <td className="privacy-box__info-rows inter">
                (a) Technical
                <br />
                (b) Use
                <br />
                (c) Marketing and
                <br />
                Communications Data
              </td>
              <td className="privacy-box__info-rows inter">
                Necessary for our legitimate interests (to identify types of
                users for our products and services, to keep our website updated
                and relevant, to develop our Project and inform our marketing
                strategy)
              </td>
            </tr>
          </table>
          <br />
          <br />
          <i className="privacy-box__italic inter">Marketing</i>
          <br />
          <br />
          We aim to provide you with choices regarding the use of certain
          personal data, including for marketing and advertising purposes.
          <br />
          <br />
          <i className="privacy-box__italic inter">Third party marketing</i>
          <br />
          <br />
          We will obtain your express consent to participate before providing
          your personal data to any third party for marketing purposes.
          <br />
          <br />
          <i className="privacy-box__italic inter">Refusal</i>
          <br />
          <br />
          V you can at any time ask us or third parties to stop sending you
          marketing communications by contacting us at any time. If you opt out
          of receiving these marketing messages, this will not apply to personal
          data provided to us as a result of a product/service purchase,
          product/service experience or other transactions.
          <br />
          <br />
          <i className="privacy-box__italic inter">Cookies</i>
          <br />
          <br />
          You can set your browser to reject all or some cookies or to notify
          you when websites set or access cookies. If you disable or refuse
          cookies, please note that some parts of this website may not be
          available or work inappropriate way For more information about the
          cookies we use, please follow the link.
          <br />
          <br />
          <i className="privacy-box__italic inter">Change of destination</i>
          <br />
          <br />
          We will only use your personal data for the purposes for which we
          collected it, unless we believe we need to use it for another reason,
          and that reason is compatible with the original purpose. If you would
          like an explanation of the compatibility of the processing for the new
          purpose with the original purpose, please contact us.
          <br />
          <br />
          Please note that we may process your personal data without your
          knowledge or consent in accordance with the rules above, if required
          or permitted by law.
          <p className="privacy-box__head-policy-text inter">
            5. Disclosure of your personal data
          </p>
          We may share your personal data with the parties listed below for the
          purposes set out in the table The purposes for which we will use your
          personal data above.
          <br />
          <br /> - Internal Third Parties as defined in the Glossary. <br />-
          Third parties to whom we may sell, transfer or merge parts of our the
          project or our assets. In addition, we may seek to acquire or partner
          with other businesses. If in our Projects changes occur, the new
          owners may use your personal data in the same way as described in this
          privacy policy
          <br />
          <br />
          We require all third parties to respect the security of your personal
          data and treat it in accordance with the law. We do not allow our
          third-party service providers to use your personal data for their own
          purposes and only allow them to process your personal data for certain
          purposes and in accordance with our instructions.
          <p className="privacy-box__head-policy-text inter">
            6. Data security
          </p>
          We have implemented appropriate security measures to prevent the
          accidental loss, use or unauthorized access, alteration or disclosure
          of your personal data. In addition, we limit access to your personal
          data to those employees, agents, contractors and other third parties
          who have a need to know. They will process Your personal data is only
          on our instructions and must be kept confidential.
          <br />
          <br />
          We have procedures in place to deal with any suspected personal data
          breaches and will notify you and any relevant authority of the breach
          if we are required to do so by law.
          <p className="privacy-box__head-policy-text inter">
            7. Your legal rights
          </p>
          In certain circumstances, you have rights under the Data Protection
          Laws. These include the right to:
          <br />
          <br /> - Request for access to your personal data. <br />- Request to
          correct your personal data.
          <br />- Request to delete your personal data.
          <br />- Objection to the processing of your personal data.
          <br />- Request to limit the processing of your personal data.
          <br />- Request for the transfer of your personal data.
          <br />- Request to withdraw consent.
          <br />
          <br />
          If you wish to exercise any of the above rights, please contact us.
          <br />
          <br />
          <i className="privacy-box__italic inter">
            No fee is usually required
          </i>
          <br />
          <br />
          You will not need to pay a fee to access your personal data (or to
          exercise any other rights). However, we may charge a reasonable fee if
          your request is clearly unreasonable, repetitive or excessive. In
          addition, we may refuse to fulfill your request under these
          circumstances.
          <i className="privacy-box__italic inter">What we may need from you</i>
          <br />
          <br />
          We may need to request certain information from you to help us verify
          your identity and to enforce your right to access your personal data
          (or to exercise any of your other rights). This is a security measure
          that ensures that personal data is not disclosed to any person who
          does not have the right to receive it. We may also contact you to ask
          you for additional information about your request in order to speed up
          our response.
          <br />
          <br />
          <i className="privacy-box__italic inter">Deadline for response</i>
          <br />
          <br />
          We try to respond to all legitimate inquiries within one month.
          Sometimes we may need more than a month if your request is
          particularly complex or you have made several requests. In this case,
          we will notify you and keep you informed.
          <p className="privacy-box__head-policy-text inter">
            8. How to contact us
          </p>
          If you have any questions about our Privacy Policy, wish to opt-out of
          our Privacy Policy, or have any complaint about our handling of your
          privacy or personal information, please contact us by email at
          brainlysooolution@gmail.com.
          <br />
          <br />
          To stop receiving our promotional emails, please email
          brainlysooolution@gmail.com or follow the instructions in any
          promotional communication you receive from us.
          <br />
          <br />
          This policy was last updated on November 22, 2024.
          <p className="privacy-box__head-policy-text inter">9. Glossary</p>
          LEGAL BASIS
          <br />
          <br />
          <i className="privacy-box__italic inter">Legitimate interest</i> means
          our interest the project in the conduct and management of our Project,
          so that we can provide you with the best services/products and the
          best and safest experience. Before processing your personal data for
          our legitimate interests, we consider and balance any potential impact
          on you (both positive and negative) and your rights. We do not use
          your personal data for activities where the impact on you outweighs
          our interests (except where we have your consent or are otherwise
          required or permitted by law). You can get more information about how
          we assess our legitimate interests in relation to any potential impact
          on you in relation to certain activities by contacting us.
          <br />
          <br />
          <i className="privacy-box__italic inter">Execution of the Contract</i>
          means the processing of your data if it is necessary for the
          performance of a contract to which you are a party or to take steps at
          your request before concluding such contract
          <br />
          <br />
          Fulfilling a legal obligation means processing your personal data if
          it is necessary to fulfill a legal obligation to which we are subject.
          <br />
          <br />
          THIRD PERSONS
          <br />
          <br />
          Internal third parties
          <br />
          <br />
          Other companies in Brainly Solution that act as joint controllers or
          processors are located in Ukraine and provide IT and system
          administration services and report to management.
          <br />
          <br />
          YOUR LEGAL RIGHTS
          <br />
          <br />
          You have the right to:
          <br />A request for access to your personal data (commonly known as a
          "data subject access request"). This enables you to obtain a copy of
          the personal data we hold about you and to check whether we are
          lawfully processing it. <br />
          <br />
          Request correction of the personal data we hold about you. This
          enables you to correct any incomplete or inaccurate data we hold about
          you, although we may need to check the accuracy of new data you
          provide to us.
          <br />
          <br />
          Request deletion of your personal data. This enables you to ask us to
          remove or remove personal data if there are no compelling reasons for
          us to continue processing it. You also have the right to ask us to
          remove or remove your personal data if you have successfully exercised
          your right to object to processing (see below), if we may have
          processed your information unlawfully or where we are required to
          delete your personal data to comply with local law. However, please
          note that we may not always be able to comply with your deletion
          request due to certain legal reasons, which will be communicated to
          you, if possible, at the time of your request.
          <br />
          <br />
          Object to the processing of your personal data if we rely on
          legitimate interests (or the interests of a third party) and there is
          something in your particular situation that leads you to object to the
          processing on this basis because you believe that it affects your
          fundamental rights and freedoms . You also have the right to object if
          we process your personal data for direct marketing purposes. In some
          cases, we may demonstrate that we have compelling legitimate grounds
          for processing your information that override your rights and
          freedoms.
          <br />
          <br />
          Request restriction of processing of your personal data. This allows
          you to ask us to suspend the processing of your personal data in the
          following cases:
          <br />
          <br />
          - If you want us to establish the accuracy of the data.
          <br /> - If our use of the data is illegal, but you do not want us to
          delete it.
          <br /> - Where you need us to keep the data, even if we no longer need
          it because you need it to establish, exercise or defend legal claims.
          <br /> - You have objected to us using your data, but we need to check
          whether we have an overriding legal basis for using it.
          <br />
          <br />
          Request to transfer your personal data to you or a third party. We
          will provide you or a third party of your choice with your personal
          data in a structured, commonly used, machine-readable format. Please
          note that this right only applies to automated information that you
          have initially consented to use or where we have used the information
          to perform a contract with you.
          <br />
          <br />
          Withdraw consent at any time if we rely on consent to process your
          personal data. However, this will not affect the lawfulness of any
          processing carried out before you withdraw your consent If you
          withdraw your consent, we may not be able to provide you with certain
          products or services. We will notify you if this is the case when you
          withdraw your consent
        </p>
      </div>
    </div>
  );
};
