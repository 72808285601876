import "./VacanciesBox.scss";
import logoImg from "../../images/logoImg.svg";
import { ArrowVacanciesPopup } from "../ArrowVacanciesPopup";
import active from "../../images/active.svg";
import inactive from "../../images/Inactive.svg";
import PropTypes from "prop-types";
import { useState } from "react";

const propTypes = {
  jobTitle: PropTypes.string.isRequired,
  jobPosition: PropTypes.string.isRequired,
  workDay: PropTypes.string.isRequired,
  workPlace: PropTypes.string.isRequired,
  workRegion: PropTypes.string.isRequired,
  vacanciesName: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export const VacanciesBox = ({
  jobTitle,
  jobPosition,
  workDay,
  workPlace,
  workRegion,
  vacanciesName,
  status,
  description,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="vacancies-box">
      <div className="vacancies-box__vacancies-content">
        <p className="vacancies-box__vacancies-name inter">{jobTitle}</p>
        <div className="vacancies-box__job-info flex">
          <div className="vacancies-box__description-container">
            <p
              className={`vacancies-box__description inter ${
                isExpanded ? "expanded" : ""
              }`}
              onClick={toggleExpand}
            >
              {description}
            </p>
          </div>
          <div
            className={`vacancies-box__indicator ${
              isExpanded ? "expanded" : ""
            }`}
            onClick={toggleExpand}
          >
            {status === "Active" ? (
              <>
                <img
                  src={active}
                  alt="active"
                  style={{ width: "14px", marginTop: "-15px" }}
                />
                <p className="vacancies-box__vacancies-status inter">Active</p>
              </>
            ) : (
              <>
                <img
                  src={inactive}
                  alt="active"
                  style={{ width: "14px", marginTop: "-15px" }}
                />
                <p className="vacancies-box__vacancies-status inter">
                  Inactive. Leave your CV and if there is a vacancy, we will
                  contact you
                </p>
              </>
            )}
          </div>
          <div className="vacancies-box__job-type flex">
            <p className="inter">{workDay}</p>
            <p className="inter">{workPlace}</p>
          </div>
          <div className="vacancies-box__job-details flex">
            <p className="vacancies-box__position inter">{jobPosition}</p>
            <p className="vacancies-box__location inter">{workRegion}</p>
          </div>
        </div>
        <div className="vacancies-box__logo-and-arrow flex">
          <div className="vacancies-box__logo-box">
            <img style={{ width: "20px" }} src={logoImg} alt="logo" />
            Brainly<span style={{ color: "#10DCE9" }}>Solution</span>
          </div>
          <ArrowVacanciesPopup vacanciesName={vacanciesName} />
        </div>
      </div>
    </div>
  );
};

VacanciesBox.propTypes = propTypes;
