import "./JobTitle.scss";
import { HeadText } from "../../components/HeadText/HeadText";
import { FlexBox } from "../../components/FlexBox";
import business from "../../images/business.svg";
import mobile from "../../images/mobile.svg";
import brush from "../../images/brush.svg";
import devOps from "../../images/devOps.svg";
import bugLine from "../../images/bug-linear.svg";
import figma from "../../images/figma.svg";
import {
  leftAnimation,
  rightAnimation,
} from "../../components/utils/sideAnimation";
import { useEffect, useRef, useState } from "react";
import { motion, useInView } from "framer-motion";

export const JobTitle = () => {
  const [hasAnimated, setHasAnimated] = useState(false);

  const ref = useRef(null);
  const isInView = useInView(ref, { threshold: 0.2 });

  useEffect(() => {
    if (isInView && !hasAnimated) {
      setHasAnimated(true);
    }
  }, [isInView, hasAnimated]);

  return (
    <div className="job-title container" ref={ref}>
      <div className="job-title__services-head head-text-box">
        <HeadText text={"Services"} />
      </div>
      <div className="job-title__all-flex-boxes">
        <div className="job-title__parent-box container flex">
          <motion.div
            className="job-title__title-child"
            initial="hidden"
            animate={hasAnimated ? "visible" : "hidden"}
            variants={leftAnimation}
            transition={{ duration: 0.7 }}
          >
            <div className="head-text-box">
              <HeadText text={"Data Science"} />
            </div>
            <FlexBox
              text={
                "We provide expert Data Science and AI solutions to boost business performance. Using advanced analytics and machine learning, we uncover insights and optimize processes. Trust us to turn your data into a powerful asset."
              }
              icon={business}
            />
          </motion.div>
          <motion.div
            className="job-title__title-child"
            initial="hidden"
            animate={hasAnimated ? "visible" : "hidden"}
            variants={rightAnimation}
            transition={{ duration: 0.7, delay: 0.3 }}
          >
            <div className="head-text-box">
              <HeadText text={"Mobile Development"} />
            </div>
            <FlexBox
              text={
                "We specialize in delivering top-tier mobile development services to enhance your business. Our expert team creates innovative and user-friendly mobile apps that drive engagement and growth. Trust us to transform your ideas into powerful mobile solutions."
              }
              icon={mobile}
            />
          </motion.div>
        </div>
        <div className="job-title__parent-box container">
          <motion.div
            className="job-title__title-child"
            initial="hidden"
            animate={hasAnimated ? "visible" : "hidden"}
            variants={leftAnimation}
            transition={{ duration: 0.7, delay: 0.5 }}
          >
            <div className="head-text-box">
              <HeadText text={"Web Technologies"} />
            </div>
            <FlexBox
              text={
                "Our talented team crafts cutting-edge mobile and web applications, ensures seamless integration and deployment, and upholds the highest quality with automated testing. Count on us to transform your ideas into robust, high-performance digital solutions."
              }
              icon={brush}
            />
          </motion.div>
          <motion.div
            className="job-title__title-child"
            initial="hidden"
            animate={hasAnimated ? "visible" : "hidden"}
            variants={rightAnimation}
            transition={{ duration: 0.7, delay: 0.7 }}
          >
            <div className="head-text-box">
              <HeadText text={"DevOps Service"} />
            </div>
            <FlexBox
              text={
                "We excel in providing DevOps services to elevate your business. Our skilled team designs innovative, user-friendly mobile apps and guarantees smooth integration and deployment through our DevOps expertise. Rely on us to turn your ideas into effective, high-performance mobile solutions."
              }
              icon={devOps}
            />
          </motion.div>
        </div>
        <div className="job-title__parent-box container">
          <motion.div
            className="job-title__title-child"
            initial="hidden"
            animate={hasAnimated ? "visible" : "hidden"}
            variants={leftAnimation}
            transition={{ duration: 0.7, delay: 0.9 }}
          >
            <div className="head-text-box">
              <HeadText text={"Software Testing"} />
            </div>
            <FlexBox
              text={
                "We specialize in QA automation to enhance your business performance. Our expert team develops high-quality mobile applications and ensures superior quality through comprehensive automated testing."
              }
              icon={bugLine}
            />
          </motion.div>
          <motion.div
            className="job-title__title-child"
            initial="hidden"
            animate={hasAnimated ? "visible" : "hidden"}
            variants={rightAnimation}
            transition={{ duration: 0.7, delay: 1 }}
          >
            <div className="head-text-box">
              <HeadText text={"UX/UI Design"} />
            </div>
            <FlexBox
              text={
                "Our team excels in creating user-friendly applications, ensuring smooth deployments, maintaining high quality with automated testing, and delivering innovative design solutions. Count on us to bring your vision to life with top-notch digital solutions."
              }
              icon={figma}
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
};
