import PropTypes from "prop-types";
import "./HeadText.scss";

const propTypes = {
  text: PropTypes.string.isRequired,
};

export const HeadText = ({ text }) => {
  return (
    <>
      <p className="header-text">{text}</p>
    </>
  );
};

HeadText.propTypes = propTypes;
