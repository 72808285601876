import "./MobCareersBox.scss";
import { HeadText } from "../../components/HeadText";
import { VacanciesBox } from "../../components/VacanciesBox";
import { useEffect, useState } from "react";
import leftArrow from "../../images/left-arrow.svg";
import rightArrow from "../../images/right-arrow.svg";
import { ref } from "firebase/database";
import { Spinner } from "../../components/Spinner";
import { database } from "../../firebase";
import { getFireDataBase } from "../../components/utils/getFireDataBase";
import { useSwipeable } from "react-swipeable";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export const MobCareersBox = () => {
  const [data, setData] = useState({});
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const dataRef = ref(database, "vacancies");
    setLoading(true);
    getFireDataBase(setData, setLoading, dataRef);
  }, []);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <>
      <div className="mob-careers-box__vacancies-head-text head-text-box">
        <HeadText text="Vacancies" />
      </div>
      <div className="mob-careers-box__careers-space container">
        <Slider {...settings}>
          {Object.keys(data).map((key) => {
            const job = data[key];
            return (
              <div className="mob-careers-box__all-cards">
                <VacanciesBox
                  key={key}
                  vacanciesName={key}
                  jobTitle={job.jobTitle}
                  workDay={job.workDay}
                  workPlace={job.workPlace}
                  jobPosition={job.jobPosition}
                  workRegion={job.workRegion}
                  status={job.status}
                  description={job.description}
                />
              </div>
            );
          })}
        </Slider>
      </div>
    </>
  );
};
