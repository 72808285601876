import { NavLink } from "react-router-dom";
import { HeadText } from "../../components/HeadText";
import "./CookiesPage.scss";

export const CookiesPage = ({ cookieText }) => {
  return (
    <div className="cookies-box container">
      <NavLink to="/" className="link-to-home">
        &#60;Home
      </NavLink>
      <div className="cookies-box__all-cookies-info">
        <h1 className="cookies-box__header-cookies-policy">
          <HeadText text="Our Cookie Policy" />
        </h1>

        <p className="cookies-box__cookies-text-policy inter">
          <p className="cookies-box__head-policy-text inter">
            About This Cookie Policy
          </p>
          This Cookie Policy explains what cookies are and how we use them. You
          should read this policy to understand what cookies are, how we use
          them, the types of cookies we use, i.e., the information we collect
          through cookies and how that information is used, and how to manage
          your cookie settings. For more information on how we use, store, and
          protect your personal data, please see our Privacy Policy. <br />
          <br />
          You can change or withdraw your consent from the Cookie Declaration on
          our website at any time. <br />
          <br />
          Learn more about who we are, how to contact us, and how we process
          personal data in our Privacy Policy. <br />
          <br />
          Your consent applies to the following domain:{" "}
          <a className="inter" href="https://www.brainlysolution.com/">
            https://www.brainlysolution.com/
          </a>
          <br />
          <br />
          You can change or withdraw your consent from the Cookie Declaration on
          our website at any time.
          <p className="cookies-box__head-policy-text inter">
            What are cookies?
          </p>
          Cookies are small data files sent by a web server and stored on a
          user’s computer. Cookies are stored on your device when the website is
          loaded in your browser. These cookies help us ensure the website
          functions properly, make it more secure, improve your user experience,
          and help us understand how the website performs and analyze where it
          needs improvement.
          <p className="cookies-box__head-policy-text inter">
            How do we use cookies?
          </p>
          Like most online services, our website uses first- and third-party
          cookies for various purposes. Third-party cookies are mostly necessary
          for the website to function correctly, and they do not collect any of
          your personal data.
          <br />
          <br />
          The third-party cookies used on our website are primarily for
          understanding how the website works, how you interact with our
          website, ensuring the security of our services, providing
          advertisements relevant to your interests, and offering a better user
          experience that helps speed up your future interactions with our
          website.
          <p className="cookies-box__head-policy-text inter">
            What types of cookies do we use?
          </p>
          <ul className="cookies-box__marker-list-cookies">
            <li className="inter">
              <b className="cookies-box__bold-text inter">Essential</b>: Some
              cookies are essential to allow you to use all the features of our
              site. They enable us to maintain user sessions and prevent
              security threats. They do not collect or store any personal
              information. For example, these cookies let you log in to your
              account, add items to your cart securely, and checkout.
            </li>
            <li className="inter">
              <b className="cookies-box__bold-text inter">Statistics</b>: These
              cookies store information like the number of website visitors,
              unique visitors, pages visited, the source of visits, and more.
              These data help us understand and analyze how well the website
              performs and where it needs improvement.
            </li>
            <li className="inter">
              <b className="cookies-box__bold-text inter">Marketing</b>: Our
              website displays advertisements. These cookies personalize the ads
              that we show you to make them meaningful. These cookies also help
              us track the effectiveness of our ad campaigns.
            </li>
            <li className="inter">
              <b className="cookies-box__bold-text inter">Targeting Cookies</b>:
              These cookies record your visit to our website, the pages you
              visited, and the links you followed. We use this information to
              make our site and the advertisements displayed on it more relevant
              to your interests. We may also share this information with third
              parties for this purpose.
            </li>
            <li className="inter">
              <b className="cookies-box__bold-text inter">Functional</b>: These
              cookies help perform certain non-essential functionalities on our
              website, such as embedding content like videos or sharing website
              content on social media platforms.
            </li>
            <li className="inter">
              <b className="cookies-box__bold-text inter">Technical</b>: These
              cookies help us store your browsing preferences, like language
              preferences, for a better and efficient experience on subsequent
              website visits.
            </li>
          </ul>
          <br />
          Please note that the following third parties may also use cookies,
          over which we have no control. These third parties may include, for
          instance, advertising networks and external service providers such as
          web traffic analysis services. These third-party cookies are likely to
          be analytical cookies, performance cookies, or targeting cookies:
          <br />
          <br />
          <ul className="cookies-box__marker-list-cookies">
            <li className="inter">
              Google Analytics:{" "}
              <a
                className="inter"
                href="https://policies.google.com/privacy?hl=en"
              >
                Click here to view Google Analytics Privacy Policy
              </a>
            </li>
            <li className="inter">
              Hotjar:{" "}
              <a className="inter" href="https://www.hotjar.com/privacy/">
                Click here to view Hotjar Privacy Policy
              </a>
            </li>
          </ul>
          <p className="cookies-box__head-policy-text inter">Cookies we use:</p>
          <ul className="cookies-box__marker-list-cookies">
            <li className="inter">
              <b className="cookies-box__bold-text inter">Necessary</b>:
              Necessary cookies help make the website usable by enabling basic
              functions like page navigation and access to secure website areas.
              The website cannot function properly without these cookies.
              <ul className="cookies-box__marker-list-in-list">
                <li className="inter">
                  Cookie Name: cookielawinfo-checkbox-necessary
                </li>
                <li className="inter">
                  Description: Indicates whether the user has consented to the
                  use of cookies.
                </li>
                <li className="inter">Duration: 1 year</li>
              </ul>
            </li>
            <li className="inter">
              <b className="cookies-box__bold-text inter">Functional</b>:
              Functional cookies help perform specific functionalities, such as
              sharing website content on social media platforms, collecting
              feedback, and other third-party features.
              <ul className="cookies-box__marker-list-in-list">
                <li className="inter">Cookie Name: _gid</li>
                <li className="inter">
                  Description: These cookies collect information on how visitors
                  use our site. We use this information to compile reports and
                  improve the website.
                </li>
                <li className="inter">Duration: 1 day</li>
              </ul>
            </li>
            <li className="inter">
              <b className="cookies-box__bold-text inter">Analytical</b>:
              Analytical cookies are used to understand how visitors interact
              with the website. These cookies help provide information on
              metrics such as the number of visitors, bounce rate, traffic
              source, etc.
              <ul className="cookies-box__marker-list-in-list">
                <li className="inter">Cookie Name: _ga_1NS62ZMNZP</li>
                <li className="inter">
                  Description: This cookie is set by Google Analytics.
                </li>
                <li className="inter">Duration: 2 years</li>
                <li className="inter">Cookie Name: _ga</li>
                <li className="inter">
                  Description: Set by Google Analytics, it calculates visitor,
                  session, and campaign data, and tracks site usage for the
                  website's analytical report. It stores information anonymously
                  and assigns a randomly generated number to recognize unique
                  visitors.
                </li>
                <li className="inter">Duration: 2 years</li>
              </ul>
            </li>
            <li className="inter">
              <b className="cookies-box__bold-text inter">Performance</b>: These
              cookies are used to understand and analyze key performance
              indicators of the website, which helps provide a better user
              experience for visitors. Advertising: Advertising cookies are used
              to provide visitors with personalized ads based on the pages you
              have previously visited and to analyze the effectiveness of ad
              campaigns.
            </li>
            <li className="inter">
              <b className="cookies-box__bold-text inter">Advertising</b>:
              Advertising cookies are used to provide visitors with personalized
              ads based on the pages you have previously visited and to analyze
              the effectiveness of ad campaigns.
              <ul className="cookies-box__marker-list-in-list">
                <li className="inter">Cookie Name: _gcl_au</li>
                <li className="inter">
                  Description: Enables the use of third-party script tags that
                  may place cookies on the user’s device.
                </li>
                <li className="inter">Duration: 2 years</li>
              </ul>
            </li>
          </ul>
          <p className="cookies-box__head-policy-text inter">
            How can I manage cookie preferences?
          </p>
          Should you decide to change your preferences later through your
          browsing session, you can click on the “Privacy & Cookie Policy” tab
          on your screen. This will display the consent notice again, allowing
          you to change your preferences or withdraw your consent entirely.{" "}
          <br />
          <br />
          Additionally, different browsers provide different methods to block
          and delete cookies used by websites. You can change your browser
          settings to block/delete cookies. To find out more about managing and
          deleting cookies, visit wikipedia.org,{" "}
          <a className="inter" href="http://www.allaboutcookies.org/">
            www.allaboutcookies.org
          </a>
          . <br />
          <br />
          All cookies, except for necessary ones, will be deleted after 1 year.
        </p>
      </div>
    </div>
  );
};
