import "./MobProjectBox.scss";
import { HeadText } from "../../components/HeadText/HeadText";
import { ProjectCard } from "../../components/ProjectCard";
import { useEffect, useState } from "react";
import { ref } from "firebase/database";
import { database } from "../../firebase";
import { getFireDataBase } from "../../components/utils/getFireDataBase";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export const MobProjectBox = () => {
  const [data, setData] = useState({});
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const dataRef = ref(database, "projects");
    setLoading(true);
    getFireDataBase(setData, setLoading, dataRef);
  }, []);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <>
      <div className="head-text-box">
        <HeadText text={"Our projects"} />
      </div>
      <div className="mob-our-project__carousel-container">
        <Slider {...settings}>
          {Object.keys(data).map((key) => {
            const project = data[key];
            return (
              <div className="mob-our-project__all-cards">
                <ProjectCard
                  key={project.name}
                  projName={project.name}
                  projDescr={project.description}
                  projPeopleNum={project.peopleNum}
                  projTimeline={project.timeline}
                  projLink={project.link}
                />
              </div>
            );
          })}
        </Slider>
      </div>
    </>
  );
};
