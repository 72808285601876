import "./BottomPanel.scss";
import logoImg from "../../images/logoImg.svg";
import { ContactBtn } from "../../components/ContactBtn";
import facebook from "../../images/facebook.svg";
import linkedin from "../../images/linkedin.svg";
import instagram from "../../images/instagram.png";

export const BottomPanel = () => {
  return (
    <footer className="bottom-panel container">
      <div className="bottom-panel__logo-box">
        <img src={logoImg} alt="logo" />
        Brainly<span style={{ color: "#10DCE9" }}>Solution</span>
      </div>
      <div className="bottom-panel__all-footer-info flex">
        <div className="bottom-panel__contact-info">
          <p className="inter">brainlysooolution@gmail.com</p>
          <p className="inter">+380997537920</p>
        </div>
        <div className="bottom-panel__footer-menu flex">
          <div className="bottom-panel__footer-menu-left flex">
            <a href="#about-us" className="bottom-panel__link-to-box inter">
              About Us
            </a>
            <a href="#services" className="bottom-panel__link-to-box inter">
              Services
            </a>
            <a href="#feedback" className="bottom-panel__link-to-box inter">
              Feedback
            </a>
          </div>
          <div className="bottom-panel__footer-menu-right flex">
            <a
              href="#business-model"
              className="bottom-panel__link-to-box inter"
            >
              Business model
            </a>
            <a href="#careers" className="bottom-panel__link-to-box inter">
              Careers
            </a>
          </div>
        </div>

        <div className="bottom-panel__follow-us">
          <p className="inter">Follow us:</p>
          <div className="bottom-panel__social-network flex">
            <a
              href="https://www.facebook.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={facebook} alt="facebook" style={{ width: "40px" }} />
            </a>
            <a
              href="https://www.linkedin.com/company/brainly-solution/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkedin} alt="linkedin" style={{ width: "40px" }} />
            </a>
            <a
              href="https://www.instagram.com/brainlysolution/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={instagram} alt="instagram" style={{ width: "40px" }} />
            </a>
          </div>
        </div>
      </div>
      <ContactBtn btnText={"Contact us"} />
    </footer>
  );
};
