import "./TermsOfUsePage.scss";
import { HeadText } from "../../components/HeadText";
import { NavLink } from "react-router-dom";

export const TermsOfUsePage = () => {
  return (
    <div className="terms-of-use-box container">
      <NavLink to="/" className="link-to-home">
        &#60;Home
      </NavLink>

      <div className="terms-of-use-box__all-terms-info">
        <h1 className="terms-of-use-box__header-terms-policy">
          <HeadText text="Our Terms of Use" />
        </h1>
        <p className="terms-of-use-box__terms-text-policy inter">
          <p className="terms-of-use-box__head-policy-text inter">
            Terms of Use:
          </p>
          These Terms of Use (“Terms of Use”) apply to the company Brainly
          Solution. The website (“Site”), located at{" "}
          <a className="inter" href="https://brainlysolution.com">
            https://brainlysolution.com
          </a>
          , governs your access to and use of the Site, as well as any
          information, text, graphics, or other materials that appear on the
          Site (“Content”). Your access to and/or use of the Site and Content is
          directly conditioned on your compliance with these Terms of Use. The
          Site is the property of Brainly Solution. By using the Site, you agree
          to these Terms of Use; if you do not agree, do not use the Site.
          <p className="terms-of-use-box__head-policy-text inter">
            Privacy Policy:
          </p>
          The Privacy Policy of Brainly Solution applies to your use of this
          Site, and its provisions are incorporated into these Terms of Use by
          reference. To view our Privacy Policy with information and notices
          regarding the collection and use of your personal information by
          Brainly Solution, click here (
          <a className="inter" href="https://www.brainlysolution.com/privacy">
            HERE IS THE LINK TO THE PRIVACY POLICY
          </a>
          ).
          <p className="terms-of-use-box__head-policy-text inter">
            Links to Other Sites:
          </p>
          This Site may contain links to other websites, some of which are
          operated by Brainly Solution, while others are operated by third
          parties. Links to third-party websites are provided solely for the
          convenience of the Site’s users. We are not responsible for the
          content of any third-party websites or any products or services that
          may be offered through these or any other sites. Third-party websites
          may have different terms and conditions. Brainly Solution is not
          liable for any damages, losses, or other harms resulting from your use
          of any third-party websites linked to this Site.
          <p className="terms-of-use-box__head-policy-text inter">Content:</p>
          All Content, including text, graphic materials, information, visual
          interfaces, photographs, logos, computer code, and other materials on
          the Site, is owned by Brainly Solution. The Content is protected by
          applicable intellectual property and unfair competition laws.
          <br />
          <br />
          Brainly Solution respects the intellectual property rights of others
          and expects that our users will do the same. Unauthorized copying,
          modification, distribution, public display, or public performance of
          copyrighted Content is a violation of the copyright owner's rights.
          <p className="terms-of-use-box__head-policy-text inter">Site Use:</p>
          You may not use the contact information provided on the site for
          unauthorized purposes, including marketing. You may not use any
          hardware or software designed to damage or interfere with the proper
          functioning of the site or to secretly intercept any system, data, or
          personal information from the site. You agree not to interrupt or
          attempt to interrupt the operation of the site in any way. Brainly
          Solution reserves the right to limit or terminate your access to or
          use of the site at any time without notice, at its sole discretion.
          <p className="terms-of-use-box__head-policy-text inter">
            Disclaimer:
          </p>
          Brainly Solution DOES NOT WARRANT THAT THE SITE OR ANY CONTENT,
          SERVICES, OR FEATURES OF THE SITE WILL BE ERROR-FREE, OR THAT ANY
          DEFECTS WILL BE CORRECTED, OR THAT YOUR USE OF THE SITE WILL PROVIDE
          SPECIFIC RESULTS. THE SITE AND ITS CONTENT ARE PROVIDED ON AN “AS IS”
          AND “AS AVAILABLE” BASIS. THE CONTENT OF THE SITE MAY CHANGE WITHOUT
          NOTICE. Brainly Solution DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED,
          INCLUDING BUT NOT LIMITED TO WARRANTIES OF ACCURACY, NON-INFRINGEMENT,
          COMMERCIAL VALUE, AND FITNESS FOR A PARTICULAR PURPOSE. Brainly
          Solution DISCLAIMS ANY LIABILITY FOR THE ACTIONS, INACTIVITY, AND
          CONDUCT OF THIRD PARTIES IN CONNECTION WITH OR RELATED TO YOUR USE OF
          THE SITE AND/OR ANY SERVICES OF Brainly Solution. YOU ASSUME FULL
          RESPONSIBILITY FOR YOUR USE OF THE SITE AND ANY LINKS. YOUR ONLY
          REMEDY AGAINST Brainly Solution IN THE EVENT OF DISSATISFACTION WITH
          THE SITE OR ANY CONTENT IS TO DISCONTINUE USING THE SITE OR SUCH
          CONTENT. THIS LIMITATION OF RELIEF IS AN ESSENTIAL PART OF THE
          AGREEMENT BETWEEN THE PARTIES.
          <p className="terms-of-use-box__head-policy-text inter">
            Use of Logo:
          </p>
          The user may not use the name Brainly Solution or the Brainly Solution
          logo in any way without prior written consent from Brainly Solution.
          <p className="terms-of-use-box__head-policy-text inter">
            Limitation of Liability:
          </p>
          Except where prohibited by law, Brainly Solution SHALL NOT BE LIABLE
          TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR
          PUNITIVE DAMAGES, INCLUDING LOST PROFITS, EVEN IF Brainly Solution HAS
          BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          <p className="terms-of-use-box__head-policy-text inter">Indemnity:</p>
          You agree to take appropriate measures to prevent Brainly Solution,
          its subsidiaries, and employees from incurring any damages, claims,
          losses, and costs arising from your use of the Site and any violations
          of these Terms of Use by you.
          <p className="terms-of-use-box__head-policy-text inter">
            Governing Law and Dispute Resolution:
          </p>
          You agree that all matters related to your access to or use of the
          Site, including all disputes, will be governed by the laws of the
          country of your residence. <br />
          <br />
          If any Content on this Site or your use of the Site violates the laws
          of the country where you are located while visiting, we ask you not to
          use the Site. You are responsible for reviewing and complying with the
          laws of your jurisdiction.
          <p className="terms-of-use-box__head-policy-text inter">
            Changes to These Terms of Use:
          </p>
          Brainly Solution reserves the right, at its sole discretion, to
          change, modify, add, or remove provisions of these Terms of Use at any
          time. You agree to periodically review these Terms of Use for changes.
          Continued use of the Site after the publication of changes means you
          accept and agree to the changes.
          <p className="terms-of-use-box__head-policy-text inter">
            Termination:
          </p>
          If you violate any part of these Terms of Use, your permission to use
          the Site, Content, and Services will be automatically terminated.
          Brainly Solution reserves the right to cancel your access to the Site,
          Content, and Services and deny their use at any time, for any reason
          or no reason.
        </p>
      </div>
    </div>
  );
};
