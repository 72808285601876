import { ContactBtn } from "../ContactBtn";
import logoImg from "../../images/logoImg.svg";
import "./HeaderMenu.scss";
import { BurgerMenu } from "../BurgerMenu";
import styled from "styled-components";
import { useEffect, useRef, useState } from "react";

const StyledMenu = styled.nav`
  display: ${({ open }) => (open ? "inline-block" : "none")};
  flex-direction: column;
  justify-content: center;
  background: #131313;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  text-align: left;
  padding: 2rem;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 1005;

  @media (min-width: 850px) {
    display: none;
  }

  a {
    font-size: 16px;
    padding: 20px 0;
    color: white;
    text-decoration: none;
    transition: color 0.2s linear;
    line-height: 30px;

    @media (max-width: 576px) {
      font-size: 16px;
    }

    &:hover {
      color: #05d4f5;
    }
  }
`;

const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) return;
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
};

export const HeaderMenu = () => {
  const [open, setOpen] = useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setOpen(false));

  return (
    <>
      <header className="head-menu">
        <div className="head-menu__menu-space container">
          <div className="head-menu__mob-menu-container">
            <div className="head-menu__logo-box">
              <img className="head-menu__logo-img" src={logoImg} alt="logo" />
              Brainly<span style={{ color: "#10DCE9" }}>Solution</span>
            </div>
            <div className="head-menu__burger-menu">
              <BurgerMenu open={open} setOpen={setOpen} />
            </div>
          </div>
          <div className="head-menu__menu-list">
            <a className="head-menu__link-btn inter" href="#about-us">
              About us
            </a>
            <a className="head-menu__link-btn inter" href="#services">
              Services
            </a>
            <a className="head-menu__link-btn inter" href="#business-model">
              Business model
            </a>
            <a className="head-menu__link-btn inter" href="#careers">
              Careers
            </a>
          </div>
          <div className="head-menu__contact-btn">
            <ContactBtn btnText={"Contact us"} />
          </div>
        </div>
        <StyledMenu open={open}>
          <div className="head-menu__opend-burger-menu">
            <a href="#about-us" className="inter">
              About us
            </a>
            <a href="#services" className="inter">
              Services
            </a>
            <a href="#business-model" className="inter">
              Business model
            </a>
            <a href="#careers" className="inter">
              Careers
            </a>
          </div>
          <div className="head-menu__burger-menu-contact-btn">
            <ContactBtn btnText={"Contact us"} />
          </div>
        </StyledMenu>
      </header>
    </>
  );
};
