import "./MissionBox.scss";
import { HeadText } from "../../components/HeadText/HeadText";
import { FlexBox } from "../../components/FlexBox";
import { motion, useInView } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import gear from "../../images/gear-icon.svg";
import lock from "../../images/security-lock-icon.svg";
import message from "../../images/message-icon.svg";
import chart from "../../images/chart-icon.svg";
import {
  leftAnimation,
  rightAnimation,
} from "../../components/utils/sideAnimation";

export const MissionBox = () => {
  const [hasAnimated, setHasAnimated] = useState(false);

  const ref = useRef(null);
  const isInView = useInView(ref, { threshold: 0.2 });

  useEffect(() => {
    if (isInView && !hasAnimated) {
      setHasAnimated(true);
    }
  }, [isInView, hasAnimated]);

  return (
    <div className="mission-section container" ref={ref}>
      <div className="head-text-box">
        <HeadText text={"Mission"} />
      </div>
      <div className="mission-section__flex-space">
        <motion.div
          className="mission-section__flex-child"
          initial="hidden"
          animate={hasAnimated ? "visible" : "hidden"}
          variants={leftAnimation}
          transition={{ duration: 0.7 }}
        >
          <FlexBox
            text={
              "Develop intuitive and user-friendly applications that streamline healthcare and fintech processes, enhancing patient care delivery and financial management"
            }
            icon={gear}
          />
        </motion.div>

        <motion.div
          className="mission-section__flex-child"
          initial="hidden"
          animate={hasAnimated ? "visible" : "hidden"}
          variants={rightAnimation}
          transition={{ duration: 0.7, delay: 0.3 }}
        >
          <FlexBox
            text={
              "Collaborate closely with healthcare professionals, financial experts, and organizations to understand their unique needs and challenges, tailoring our solutions accordingly"
            }
            icon={message}
          />
        </motion.div>

        <motion.div
          className="mission-section__flex-child"
          initial="hidden"
          animate={hasAnimated ? "visible" : "hidden"}
          variants={leftAnimation}
          transition={{ duration: 0.7, delay: 0.7 }}
        >
          <FlexBox
            text={
              "Prioritize data security, privacy, and compliance with regulatory standards to ensure the trust and confidence of our users across healthcare and fintech domains"
            }
            icon={lock}
          />
        </motion.div>

        <motion.div
          className="mission-section__flex-child"
          initial="hidden"
          animate={hasAnimated ? "visible" : "hidden"}
          variants={rightAnimation}
          transition={{ duration: 0.7, delay: 0.5 }}
        >
          <FlexBox
            text={
              "Continuously innovate and adapt to the evolving landscape of healthcare and financial technology, staying at the forefront of advancements to deliver the most effective solutions"
            }
            icon={chart}
          />
        </motion.div>
      </div>
    </div>
  );
};
