import "./ModelBoxAnim.scss";
import PropTypes from "prop-types";

const propTypes = {
  model: PropTypes.string.isRequired,
  aboutModel: PropTypes.string.isRequired,
};

export const ModelBoxAnim = ({ model, aboutModel }) => {
  return (
    <>
      <div className="business-box">
        <p className="business-box__header-model-text bruno-ace">{model}</p>
        <p className="business-box__about-model inter">{aboutModel}</p>
      </div>
    </>
  );
};

ModelBoxAnim.propTypes = propTypes;
