import "./style/App.scss";
import { useEffect, useState } from "react";
import { database } from "../firebase";
import { getFireDataBase } from "../components/utils/getFireDataBase";
import { ref } from "firebase/database";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Route, Routes } from "react-router-dom";
import { HomePage } from "../pages/HomePage/HomePage";
import { CookiesPage } from "../pages/CookiesPage";
import { PrivacyPage } from "../pages/PrivacyPage";
import { TermsOfUsePage } from "../pages/TermsOfUsePage";
import { PolicyMenu } from "../sections/PolicyMenu/PolicyMenu";

export const App = () => {
  const [data, setData] = useState({});
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const dataRef = ref(database, "policy");
    getFireDataBase(setData, setLoading, dataRef);
  }, []);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        transition={Slide}
        progressClassName="custom-progress"
      />
      <Routes>
        <Route element={<PolicyMenu />}>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/cookies"
            element={<CookiesPage cookieText={data.cookies} />}
          />
          <Route
            path="/privacy"
            element={<PrivacyPage privacyText={data.privacy} />}
          />
          <Route
            path="/terms-of-use"
            element={<TermsOfUsePage termsOfUse={data.termsOfUse} />}
          />
        </Route>
      </Routes>
    </>
  );
};
