import { NumAnimation } from "../../components/NumAnimation/NumAnimation";
import "./StatisticBox.scss";
import { useEffect, useState } from "react";
import { ref } from "firebase/database";
import { database } from "../../firebase";
import { getFireDataBase } from "../../components/utils/getFireDataBase";
import { Spinner } from "../../components/Spinner";

export const StatisticBox = () => {
  const [data, setData] = useState({});
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const dataRef = ref(database, "statistics");
    getFireDataBase(setData, setLoading, dataRef);
  }, []);

  return (
    <div className="about-statistic container flex">
      <div className="about-statistic__smart-team">
        <p className="about-statistic__motivation-text">
          Don't miss your <span style={{ color: "#10DCE9" }}>chance</span> -
          choose <span style={{ color: "#10DCE9" }}>new</span> technologies and
          a <span style={{ color: "#10DCE9" }}>smart</span> team
        </p>
      </div>

      <div className="about-statistic__stat-numbers flex">
        {isLoading ? (
          <div className="spinner container">
            <Spinner />
          </div>
        ) : (
          <>
            <NumAnimation
              number={data.finishedProj}
              textInfo={"number of finished projects"}
              plus={""}
            />
            <NumAnimation
              number={data.peopleInTeam}
              textInfo={"number of people in the team"}
              plus={""}
            />
            <NumAnimation
              number={data.experience}
              textInfo={"years of experience in the IT field"}
              plus={"+"}
            />
            <NumAnimation
              number={data.domains}
              textInfo={"domains we work with"}
              plus={"+"}
            />{" "}
          </>
        )}
      </div>
    </div>
  );
};
