import { HeadText } from "../../components/HeadText";
import "./PolicyMenu.scss";
import { NavLink, Outlet } from "react-router-dom";

export const PolicyMenu = () => {
  return (
    <>
      <Outlet />
      <div className="policy-box container">
        <NavLink to="/cookies" className="policy-box__cookie-info-page inter">
          Cookies
        </NavLink>
        <NavLink to="/privacy" className="policy-box__policy-info-page inter">
          Privacy
        </NavLink>
        <NavLink
          to="/terms-of-use"
          className="policy-box__terms-info-page inter"
        >
          Terms of Use
        </NavLink>
        <p className="policy-box__copyright inter">
          © 2024 All Rights Reserved | BrainlySolution
        </p>
      </div>
    </>
  );
};
