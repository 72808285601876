import { GradientAnimation } from "../../components/GradientAnimation/GradientAnimation";
import "./PartnershipBox.scss";

export const PartnershipBox = () => {
  return (
    <div className="gradient-bg container">
      <div className="gradient-bg__text-position">
        <GradientAnimation />
      </div>
    </div>
  );
};
